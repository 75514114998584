import React from "react";
import {
    Col,
    Divider,
    Drawer,
    Form,
    Input,
    Row,
} from "antd";
import { Button } from "src/components/actions/button";
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg";
import { useAppData } from "src/store/app-data";
import {
    whiteSpaceValidation
} from "src/utils/helpers";
import styled from "styled-components";
import { PlusOutlined} from "@ant-design/icons";

export function PushDrawer({
                               title,
                               onClose,
                               onSave,
                               allUsers,
                               idUser = null
                           }) {

    const layoutConfig = useAppData((state) => state.layoutConfig);
    const [ isLoading, setIsLoading] = React.useState(false)

    const [form] = Form.useForm();



    function prepareData(values) {
        let userIds = [];
        const data = {
            mailingType: "pushByToken",
            body: values.body,
            title: values.title,
            userIds: idUser ? [idUser] : [],
            allUsers: allUsers,
            enableDefaultSounds: true,
            cloudMessagePriorityEnum: "highest",
            additionalData: values.additionalData
        };

        return data;
    }


    async function onSubmit(values) {
        setIsLoading(true);
        if (values.additionalData) {
            let data = {};
            values.additionalData.forEach((item) => {
                let key = item["key"]
                data[key] = item["value"]
            })
            values.additionalData = data
        }
        const baseData = prepareData(values)

        try {
            await onSave(baseData);
            onClose();
            form.resetFields();
        } finally {
            setIsLoading(false);
        }
    }

    const id = idUser ? idUser : "all";
    function handleClose() {
        onClose();
        form.resetFields();
    }

    return (
        <DrawerEdit
            width={layoutConfig.isMobile ? "90%" : "80%"}
            title={title}
            onClose={handleClose}
            open
        >
            <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSubmit(values)}>

                {/*{ allUsers && (<StyledFormItem
                    key={id + "_allUsers"}
                    label={"Всем пользователям"}
                    name={"allUsers"}
                >
                    <Radio.Group
                        buttonStyle="solid"
                        value={allUsers ? true : false}
                    >
                        <Radio.Button value={true}>Да</Radio.Button>
                        <Radio.Button value={false}>Нет</Radio.Button>
                    </Radio.Group>
                </StyledFormItem>)}*/}

                <StyledFormItem
                    label={"Заголовок"}
                    key={id + "_title"}
                    name={"title"}
                    rules={[
                        {
                            required: true,
                            message: "Введите Заголовок",
                        },
                    ]}
                >
                    <Input maxLength={255}/>
                </StyledFormItem>

                <StyledFormItem
                    label={"Тело сообщения"}
                    key={id + "_body"}
                    name={"body"}
                    rules={[
                        {
                            required: true,
                            message: "Введите текст",
                        },
                    ]}
                >
                    <Input maxLength={255} />
                </StyledFormItem>

                <Divider orientation="left">Добавить поля</Divider>

                <Form.List name="additionalData">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...field }, index) => (
                                <Form.Item required={false} key={key}>

                                    <RowFlex>
                                        <StyledFormItem
                                            {...field}
                                            key={id + "_key"}
                                            label={"Ключ"}
                                            name={[name, "key"]}
                                            style={{ width: "100%" }}
                                            rules={[
                                                (formInstance) => ({
                                                    message: "Введите название!",
                                                    required:
                                                    formInstance.getFieldValue("additionalData")?.[key]?.key,
                                                }),
                                                {
                                                    validator: whiteSpaceValidation,
                                                },
                                            ]}
                                        >
                                            <Input style={{ width: "100%" }} type={"text"} placeholder={"Ключ"} />
                                        </StyledFormItem>
                                        <div style={{ margin: "10px 0 0 0" }}>:</div>
                                        <StyledFormItem
                                            style={{ width: "100%" }}
                                            {...field}
                                            key={id + "_value"}
                                            label={"Значение"}
                                            name={[name, "value"]}
                                            rules={[
                                                (formInstance) => ({
                                                    message: "Введите значение!",
                                                    required:
                                                    formInstance.getFieldValue("additionalData")?.[key]?.key,
                                                }),
                                                {
                                                    validator: whiteSpaceValidation,
                                                },
                                            ]}
                                        >
                                            <Input type={"text"} maxLength={255} style={{ width: "100%" }} placeholder={"Значение"} />
                                        </StyledFormItem>
                                    </RowFlex>
                                </Form.Item>
                            ))}

                            <Row gutter={28}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{ width: "100%" }}
                                            icon={<PlusOutlined />}
                                        >
                                            Добавить поля
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form.List>

                <ButtonRow>
                    <ButtonCol>
                        <Button
                            danger
                            onClick={() => {
                                form.resetFields();
                            }}
                            icon={<RotateClockwiseSvg />}
                        >
                            Вернуть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button onClick={handleClose} icon={<CloseSvg />}>
                            Закрыть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button
                            icon={<SaveSvg />}
                            type="primary"
                            htmlType="submit"
                            disabled={isLoading}
                        >
                            Отправить
                        </Button>
                    </ButtonCol>
                </ButtonRow>
            </FormEdit>
        </DrawerEdit>
    );
}

const DrawerEdit = styled(Drawer)``;

const FormEdit = styled(Form)``;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`;

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`;

const RowFlex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
`

