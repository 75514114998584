import React from "react";
import {DatePicker, Form, Input, Row} from "antd";
import styled from "styled-components";
import {RotateClockwiseSvg, SaveSvg} from "../../components/Svg";
import {Button} from "../../components/actions/button";
import {SearchOutlined} from "@ant-design/icons";

export function OrderFilers({handleSearch, handleClearFilter}) {

    const [form] = Form.useForm()

    function onSearch(values){
        let data = {
            phone: values.phone?.trim() ?? null,
            email: values.email?.trim() ?? null,
            dateFrom: values.dateFrom ? values.dateFrom.toISOString() : null,
            dateTo: values.dateTo ? values.dateTo.toISOString() : null,
            innerId: values.innerId?.trim() ?? null,
            totalPriceWithDiscount: values.totalPriceWithDiscount?.trim() ?? null
        }
        handleSearch(data)
    }

    function onClear() {
        form.resetFields()
        handleClearFilter()

    }

    return (
        <>
           <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSearch(values)}>
               <StyledFieldsContainer>
                   <StyledFormItem
                       key={ "sort_phone" }
                       label={"По номеру покупателя"}
                       name={"phone"}
                   >
                       <Input placeholder="Номер телефона" />
                   </StyledFormItem>
                   <StyledFormItem
                       key={ "sort_email" }
                       label={"По email покупателя"}
                       name={"email"}
                   >
                       <Input placeholder="Email"/>
                   </StyledFormItem>
                   <StyledFormItem
                       key={"innerId"}
                       label={"По номеру заказа"}
                       name={"innerId"}
                   >
                       <Input placeholder="Номер заказа" />
                   </StyledFormItem>
                   <StyledFormItem
                       key={"totalPriceWithDiscount"}
                       label={"По cумме заказа"}
                       name={"totalPriceWithDiscount"}
                   >
                       <Input placeholder="Сумма заказа" />
                   </StyledFormItem>
                   <StyledFormItem
                       key={ "sort_date_from" }
                       label={"По дате (от)"}
                       name={"dateFrom"}
                   >
                       <DatePicker showTime />
                   </StyledFormItem>
                   <StyledFormItem
                       key={"sort_date_to"}
                       label={"По дате (до)"}
                       name={"dateTo"}
                   >
                       <DatePicker showTime />
                   </StyledFormItem>
               </StyledFieldsContainer>
               <ButtonsContainer>
                   <Button
                       danger
                       onClick={onClear}
                       icon={<RotateClockwiseSvg />}
                   >
                       Сбросить
                   </Button>
                   <Button icon={<SearchOutlined />}  type="primary" htmlType="submit">
                       Искать
                   </Button>
               </ButtonsContainer>

           </FormEdit>
        </>
    )
}

const FormEdit = styled(Form)`
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-top: 20px;
`

const StyledFormItem = styled(Form.Item)`
    margin-bottom: 10px;
`

const ButtonsContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`

const StyledFieldsContainer = styled("div")`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 20px;
`