import api from "../../../api";
import {notification} from "antd";
import React from "react";

export function useManageNotifications(onOpenClosePushDrawer, onOpenClosePushAllDrawer) {
    const [pushUserId, setPushUserId] = React.useState(false);

    const onUserPush = (id) => {
        setPushUserId(id)
        onOpenClosePushDrawer()
    }

    const onSendPush = async (data) => {
        try {
            await api.post("start-mailing", data);
            notification.success({ message: "PUSH отправлен" });
            onOpenClosePushAllDrawer()
        } catch (error) {
            console.log("Ошибка отправки", error);
            notification.error({ message: "Ошибка отправки" });
            throw error;
        }
    }

    const onSendPushToUser = async (data) => {
        try {
            await api.post("send-push-user", data);
            notification.success({ message: "PUSH отправлен" });
            setPushUserId(false)
            onOpenClosePushDrawer()
        } catch (error) {
            console.log("Ошибка отправки", error);
            notification.error({ message: "Ошибка отправки" });
            throw error;
        }
    }

    return {
        pushUserId,
        onUserPush,
        onSendPush,
        onSendPushToUser
    }
}