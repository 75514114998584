import React from "react"
import { Table as AntTable } from "antd"
import { ExtraButtons } from "src/components/extra-buttons"
import { Header } from "src/components/layout/header"
import { VisibilityProvider } from "src/components/visibility-provider"
import { useUpdateHeaderTitle } from "src/hooks/use-update-header-title"
import { EditDrawer } from "src/pages-lib/orders/edit-drawer"
import { useGetOrders, useManageItems } from "src/pages-lib/orders/model"
import { getFormattedItems } from "src/pages-lib/orders/model/use-get-orders"
import { getColumns } from "src/pages-lib/orders/table-columns"
import {OrderFilers} from "src/pages-lib/orders/filters";
import styled from "styled-components"
import {OrderExpandableRow} from "../pages-lib/orders/order-expandable-row";
import {ChangePaymentDrawer} from "../pages-lib/orders/change-payment-drawer";
import {RefusedDrawer} from "../pages-lib/orders/refused-drawer";

export default function Orders() {
  const [editDrawerState, setEditDrawerState] = React.useState(false);
  const [changePaymentDrawerState, setChangePaymentDrawerState] = React.useState(false);
  const [refusedDrawerState, setRefusedDrawerState] = React.useState(false);
  const [changePaymentValues, setChangePaymentValues] = React.useState(false);
  const [refusedData, setRefusedData] = React.useState([]);
  const [refusedDelivery, setRefusedDelivery] = React.useState(false)
  const [refusedAll, setRefusedAll] = React.useState(false)

  const {
      items,
      isLoading,
      pagination,
      options,
      refetch: getItems,
      handleTableChange,
      handleSearchTable,
      handlePaymentCheck,
      resendToCrm
  } = useGetOrders();

  const { editData, onEditItem, onSaveChange, sendPaymentUrlToEmail, sendRefusedItemsData } =
      useManageItems(items, getItems, onSuccessEdit, closeRefusedDrawerState, clearRefusedData, options);

  const headerTitle = useUpdateHeaderTitle({
    items,
    defaultTitle: "0 Заказов",
    arrType: ["Заказ", "Заказы", "Заказов"],
  });


  const columns = getColumns(onEditItem)

  function onSuccessEdit() {
    setEditDrawerState(!editDrawerState)
  }

  function handleSetChangeValue(values) {
      setChangePaymentValues(values)
  }

  function clearRefusedData() {
      setRefusedAll(false)
      setRefusedData([])
  }

  function closeChangePaymentDrawer() {
      setChangePaymentDrawerState(false)
  }

    function openChangePaymentDrawer() {
        setChangePaymentDrawerState(true)
    }

    function closeRefusedDrawerState() {
        setRefusedDrawerState(false)
        clearRefusedData()
        clearRefusedDeliveryClear()
    }

    function clearRefusedDeliveryClear() {
      setRefusedDelivery(false)
    }

    function openRefusedDrawerState() {
        setRefusedDrawerState(true)
    }

    function closeAndClear() {
        setEditDrawerState(false)
        closeRefusedDrawerState()
    }

  return (
    <>
      <Header title={headerTitle} extra={<ExtraButtons />} />
      <OrderFilers handleSearch={ handleSearchTable } />
      <StyledTable
        dataSource={getFormattedItems(items)}
        columns={columns}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
        expandable={{
            expandedRowRender: (record) =>
                <OrderExpandableRow
                    data={record}
                    onPayment={handlePaymentCheck}
                    onSend={resendToCrm}
                />
        }}
      />

      <VisibilityProvider visible={editDrawerState}>
        <EditDrawer
          onClose={() => closeAndClear()}
          initialValues={editData}
          title={"Просмотр заказа"}
          handleSetChangeValue={handleSetChangeValue}
          openChangePaymentDrawer={openChangePaymentDrawer}
          openRefusedDrawerState={openRefusedDrawerState}
          sendPaymentUrlToEmail={sendPaymentUrlToEmail}
          refusedData={refusedData}
          setRefusedData={setRefusedData}
          refusedDelivery={refusedDelivery}
          setRefusedDelivery={setRefusedDelivery}
          setRefusedAll={setRefusedAll}
        />
      </VisibilityProvider>

      <VisibilityProvider visible={changePaymentDrawerState}>
          <ChangePaymentDrawer
              title={"Изменить тип оплаты"}
              onClose={closeChangePaymentDrawer}
              initialValues={changePaymentValues}
              paymentTypesOption={options.paymentTypes}
              handleSetChangeValue={handleSetChangeValue}
              onSaveChange={onSaveChange}
          />
      </VisibilityProvider>

        <VisibilityProvider visible={refusedDrawerState}>
            <RefusedDrawer
                title={"Возврат позиций"}
                onClose={closeRefusedDrawerState}
                initialValues={refusedData}
                orderData={editData}
                refusedDelivery={refusedDelivery}
                onSaveChange={sendRefusedItemsData}
                allRefused={refusedAll}
            />
        </VisibilityProvider>
    </>
  )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`
