import React from "react"
import moment from "moment"
import api from "../../../api";
import {notification} from "antd";
import {DELiVERY_GUID} from "../../../config";

const statuses = {
  0: "Ожидание информации от клиента",
  2: "Ожидание информации от исполнителя",
}

export function useManageItems(items, getItems, onSuccessEdit, closeRefusedDrawerState, clearRefusedData) {
  const [editData, setEditData] = React.useState(null)

  function formattedEditItem(item, id) {
      let allRefused = true

      item.dashboardOrder?.dashboardOrderItems?.forEach((orderItem) => {
          if (orderItem !== null) {
              allRefused = false
          }
      })
      return {
          orderId: id,
          dashboardOrder: {
              allRefused: allRefused,
              externalGuid: item.dashboardOrder.externalGuid,
              deliveryStatus:
                  statuses[item.dashboardOrder.deliveryStatus] ??
                  item.dashboardOrder.deliveryStatus,
              deliveryType:
              item.dashboardOrder.dashboardDeliveryType?.name.find((el) => el.language === "ru").content,
              deliveryTypeSlug: item.dashboardOrder.dashboardDeliveryType?.slug,
              deliveryAddress:
                  item.dashboardOrder.dashboardDeliveryType?.name.find((el) => el.language === "en").content === "delivery"
                      ? item.dashboardOrder.dashboardUserAddresses?.dashboardAddress?.textAddress ?? "Не получили адрес"
                      : item.dashboardOrder.dashboardPoint.addressString,
              paymentType:
                  item.dashboardOrder.dashboardPaymentType?.name.find(
                      (el) => el.language === "ru"
                  ).content ?? item.dashboardOrder.dashboardPaymentType?.slug,
              paymentTypeId: item.dashboardOrder.dashboardPaymentType?.id,
              descriptionToPayment: item.dashboardOrder.descriptionToPayment,
              outNumber: item.dashboardOrder.outNumber,
              email: item.dashboardOrder.email,
              commentToOrder: item.dashboardOrder.commentToOrder,
              commentToAddress: item.dashboardOrder.commentToAddress,

              isNeedCreateClient: item.dashboardOrder.isNeedCreateClient,
              isNeedAccept: item.dashboardOrder.isNeedAccept,
              isNeedOutNumber: item.dashboardOrder.isNeedOutNumber,
              isDiscount: item.dashboardOrder.isDiscount,
              isPaid: item.dashboardOrder.isPaid,

              createdAt: item.dashboardOrder.createdAt
                  ? moment(item.dashboardOrder.createdAt, "DD-MM-YYYY HH:mm")
                  : null,
              updatedAt: item.dashboardOrder.updatedAt
                  ? moment(item.dashboardOrder.updatedAt, "DD-MM-YYYY HH:mm")
                  : null,
              deletedAt: item.dashboardOrder.deletedAt
                  ? moment(item.dashboardOrder.deletedAt, "DD-MM-YYYY HH:mm")
                  : null,
              deliveryDate: item.dashboardOrder.deliveryDate
                  ? moment(item.dashboardOrder.deliveryDate, "DD-MM-YYYY HH:mm")
                  : null,

              idForCrm: item.dashboardOrder.idForCrm,
              isLessPacking: item.dashboardOrder.isLessPacking,
              isCutlery: item.dashboardOrder.isCutlery,
              isToAnotherPerson: item.dashboardOrder.isToAnotherPerson,

              otherPhone: item.dashboardOrder.otherPhone,
              otherName: item.dashboardOrder.otherName,
              innerId: item.dashboardOrder.innerId,
              onlinePaymentId: item.dashboardOrder.onlinePaymentId,
              paymentUrl: item.dashboardOrder.paymentUrl,
              isSendToCrm: item.dashboardOrder.isSendToCrm,
              totalPrice: item.dashboardOrder.totalPrice,
              deliveryPrice: item.dashboardOrder.deliveryPrice,
              totalPriceWithDiscount: item.dashboardOrder.totalPriceWithDiscount,
              emailForCertificate: item.dashboardOrder.emailForCertificate,
          },
          dashboardUser: {
              phone: item.dashboardUser.phone,
              email: item.dashboardUser.email,
              name: item.dashboardUser.dashboardUserInfo.name ?? null,
              surname: item.dashboardUser.dashboardUserInfo.surname ?? null,
              externalGuid: item.dashboardUser.dashboardUserInfo.externalGuid ?? null,
              isStaff: item.dashboardUser.dashboardUserInfo.isStaff,
          },
          dashboardOrderItems: item.dashboardOrder.dashboardOrderItems
              .filter(product => product.externalOrderItemUuid !== DELiVERY_GUID)
              .map(
              (product, index) => ({
                  name:
                      `Товар ${index + 1}: ` +
                      product.dashboardProduct.name?.find((el) => el.language === "ru")
                          .content,
                  id: product.id,
                  count: product.count - product.refusedCount,
                  price: product.price,
                  totalPrice: product.price * (product.count - product.refusedCount),
                  discount: product.discount,
                  size: product.size,
                  discountName: product.discountName,
                  externalOrderUuid: product.externalOrderUuid,
                  externalOrderItemUuid: product.externalOrderItemUuid,
                  externalGuid: product.externalGuid,
                  priceWithDiscount: product.priceWithDiscount,
                  totalPriceWithDiscount: product.totalPriceWithDiscount- product.refusedSum,
                  refusedAt: product.refusedAt ?? null,
                  refusedCount: product.refusedCount,
                  refusedSum: product.refusedSum
              })
          ),
      }
  }
  async function onEditItem(id) {
      await api.get(`orders/${id}`)
          .then((response) => {
              const item = formattedEditItem(response.data.order, id)
              setEditData(item)
              onSuccessEdit()
          })

  }

  async function onSaveChange(values, id) {
      try {
          await api.post("orders/changePaymentType", values)
              .then((response) => {
                  const item = formattedEditItem(response.data.order, id)
                  setEditData(item)
              })

          notification.success({ message: "Оплата изменена" })
      } catch (error) {
          console.log("Ошибка изменения", error)
          notification.error({ message: "Ошибка изменения" })
          throw error
      }
  }

  async function sendPaymentUrlToEmail(values) {
      try {
          await api.post("orders/sendPaymentLink", values)

          notification.success({ message: "Сылка отправлена" })
      } catch (error) {
          console.log("Ошибка отправки", error)
          notification.error({ message: "Ошибка отправки" })
          throw error
      }

  }
    async function sendRefusedItemsData(values, orderId) {
        try {
            await api.post(`orders/refused/v2/${orderId}`, values)

            notification.success({ message: "Данные отправлены" })

        } catch (error) {
            console.log("Ошибка отправки", error)
            notification.error({ message: "Ошибка отправки" })
            throw error
        } finally {
            closeRefusedDrawerState()
            clearRefusedData()
            onSuccessEdit()
            setEditData(null)
        }

    }

  return { editData, onEditItem, onSaveChange, sendPaymentUrlToEmail, sendRefusedItemsData }
}
